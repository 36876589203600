import React, { ChangeEvent, FC, Fragment, useState } from "react";
import { login } from "../../auth";


export const Login: FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = (): void => {
    setIsLoading(true);
    login({
      email,
      password,
    })
      .then(() => {
        window.history.back();
      })
      .catch(e => {
        console.error(e);
        setIsLoading(false);
        setPassword("");
      });
  };

  if (isLoading) {
    return <h2>Loading...</h2>;
  }

  return (
    <Fragment>
      <label>
        <div>E-mail</div>
        <input
          type="email"
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setEmail(e.target.value);
          }}
        />
      </label>
      <label>
        <div>Adgangskode</div>
        <input
          type="password"
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            setPassword(e.target.value);
          }}
        />
      </label>
      <br />
      <button onClick={handleLogin}>Log in</button>
    </Fragment>
  );
};
